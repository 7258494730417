import type { SubscribeViewModel } from "@/types/models/subscribe/subscribe-view-model";
import BaseService from "./base-service";
import type { ContactUsViewModel, OrderNowViewModel } from "@/types/models/email/contact-us-view-model";

class MessageService extends BaseService {
    async subscribeNewsletter(model: SubscribeViewModel) {
        return await this.post('/mail/subscribeNewsletter', model);
    }

    async verifyRecaptcha(response: string) {
        return await this.post<{ success: Boolean, challenge_ts: Date, hostname: String, "error-codes"?: [] }>('/googleRecaptcha/verify', { response });
    }

    async contactUsSend(data: ContactUsViewModel) {
        await this.post('/mail/contactUsSend', data);
    }

    async freeQuateSend(data: OrderNowViewModel) {
        await this.post('/mail/freeQuateSend', data);
    }
}

export default new MessageService();